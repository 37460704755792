import React from "react";

import portIcon from "../../img/anchor.svg";
import airportIcon from "../../img/airport.svg";
import cityIcon from "../../img/city.svg";
import trainIcon from "../../img/train.svg";

import { Option } from "./styled";
import { LocationDto } from "../../dto/Location/LocationDto";
import getLocalizedName from "../../utils/getLocalizedName";
import { SupportedLanguages } from "../../viewModels/Settings/TranslationModel";

export function getIconByLocationType(type: "AIRPORT" | "CITY" | "PORT" | "RAILWAY") {
  let icon;
  let alt;

  if (!type) {
    return null;
  }

  switch (type) {
    case "AIRPORT":
      icon = airportIcon;
      alt = "airport icon";
      break;
    case "PORT":
      icon = portIcon;
      alt = "port icon";
      break;
    case "CITY":
      icon = cityIcon;
      alt = "city icon";
      break;
    case "RAILWAY":
      icon = trainIcon;
      alt = "train icon";
      break;
    default:
      icon = airportIcon;
      alt = "airport icon";
      break;
  }

  return <img src={icon} alt={alt} />;
}

export function generateLocationLabel(location: LocationDto, lang: SupportedLanguages) {
  return (
    <Option>
      {getIconByLocationType(location.type)}
      <span>{getLocalizedName(location, lang)}</span>
    </Option>
  );
}
