import qs from "qs";

import { SERVER_ADDRESSES } from "../../../config/serverAddressesConfig";

import { LocationDto } from "../../../dto/Location/LocationDto";
import { DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS } from "../../../config/apiEndPoints/DictionaryApiEndsPoints/LocationsController";

const locationPath = `${SERVER_ADDRESSES.DICTIONARY_API}/${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.CONTROLLER_PATH}${DICTIONARY_API_LOCATIONS_CONTROLLER_END_POINTS.BASIC_INFO}`;

export async function fetchLocations(abortController: AbortController, pattern: string): Promise<Array<LocationDto>> {
  const queryStr = qs.stringify({ pattern }, { addQueryPrefix: true });
  const response = await fetch(`${locationPath}${queryStr}`, {
    signal: abortController.signal,
  });
  return response.json();
}
